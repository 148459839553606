.mapGL {
    position: absolute;
}

.btn-close {
    z-index: 1;
}

.map-gl-canvas {
    z-index: 1;
}

.overlays {
    z-index: 2;
}

.geolocateControlRTMapStyle {
    left: 20px;
    bottom: 140px;
    transform: scale(1.25);
    position: absolute;
    z-index: 1;

    @media screen and (max-width: 768px) {
        left: unset;
        right: 20px;
        bottom: unset;
        top: 225px !important;
    }
}


.zoomControlStyle {
    bottom: 150px;
    right: 20px;
    transform: scale(1.25);
    position: absolute;
    z-index: 1;

    @media screen and (max-width: 768px) {
        bottom: unset;
        top: 110px !important;
    }
}

.blur-popup {
    img {
        transition: all 250ms ease-in-out;
    }

    div {
        transition: all 50ms ease-in-out;
    }

    &:hover .lock-btn {
        display: none;
    }

    &:hover .authentication_btn {
        display: block;
    }

    &:hover img {
        transform: scale(1.1);
    }

    &:hover {

        &>div {
            filter: blur(3px);
        }
    }

    .roadtrip-elements {
        pointer-events: none;
    }

    overflow: hidden;

    &>div {
        filter: blur(2px);
    }

}