.card_container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 30px;
  margin-left: 15px;
  margin-top: 10px;
  box-shadow: 1px 1px 12px #888;
}

.card_container_circle {
  margin-left: 15px;
  margin-top: 10px;
  display: block;
  width: 35px;
  height: 35px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  text-align:center;
  box-shadow: 1px 1px 12px #888;
}


.card_container_movable {
  display: block;
  width: 25px;
  height: 25px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  text-align:center;
  background-color: white;
}

.card_container_circle img{
  margin-top:7px;
}

.card_text {
  margin: auto;
}

.card_image {
  margin-left: 5px;
  margin-bottom: 10px;
}