.maps {
  z-index: 1;
  right: 0;
  position: fixed !important;
  display: flex;
  height: calc(100vh - 90px);
  @media screen and (max-width: 991px) {
    height: calc(100vh - 150px);
  }
  @media screen and (max-width: 768px) {
    height: calc(100vh - 100px);
    top: 0 !important;
  }
}

.search-view-page {
  position: relative !important;
  padding-top: 90px;
}

[data-rsbs-header] {
  padding-bottom: 10px;
}

.result-list {

  &--title {
    padding-left: 2rem;
  }

  &--cards {
    display: flex;

    .rows {
      flex: 0 1 auto;
    }

    @media screen and (max-width: 750px) {
      overflow-y: hidden;
      height: auto;
    }
  };

  @media screen and (max-width: 750px) {
    max-height: none;
  }
  .touristic-card {
    width: 100%;
    max-width: 750px !important;
  }

  .card_container_filter {
    display: block;
    width: 25px;
    height: 25px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    text-align:center;
    background-color: white;
    border: 0.01vw solid;
  }
}

.filterDiv {
  position: absolute;
  background-color: white !important;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh !important;
  z-index: 10000;
  display: flex;
  flex-direction: column;
}