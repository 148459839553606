.card-image {
  width: 80%;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.text-card {
  &__title {
    font-size: 21px;
    font-weight: 600;
  }
  &__subtitle {
    font-size: 22px;
    font-weight: bold;
  }
  &__description {
    font-size: 20px;
  }

}