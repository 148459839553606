.roadtrip {
  &__loader {
    height: 100vh;
  }

  &__buttonIndex {
    z-index: 1;
  }

  &__pagination {
    bottom: 0;
  }

  &__input-container {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 40px;
    box-shadow: 1px 0 5px #00000041;
    display: flex;
    flex-direction: column;
    left: 0;
    min-height: 51px;
    padding: 14px 38px;
    position: absolute;
    top: 0;
    cursor: text;
    z-index: 99;

    .adresse {
      display: none;
    }

    .autocompletePlace-clear {
      top: 3px;
    }
  }

  &__input-search {
    height: 51px;

  }

  &__input-location {
    width: 100%;
  }

  &__input {
    letter-spacing: 0;
    line-height: 16px;
    text-align: start !important;
    padding-left: 10px !important;
    margin: 0 !important;
    width: 90%;
    height: 21px !important;
    border: none !important;
    white-space: nowrap;
  }
}

.add-custom-ts {
  background-color: transparent !important;
  border-color: transparent !important;
}

.title-button {
  right: 2px;
  position: absolute;
  top: 1.25px;
  display: flex;
}

.autocompletePlace {
  position: relative;
  transition: all 0.5s ease;
  width: 100% !important;

  .adresse {
    display: none;
  }

  .autocompletePlace-clear {
    top: 50%;
    transform: translate(100%, -50%);
  }

  .autocompletePlace-results {
    background-color: white;
    border-radius: 10px;

    li {
      padding: 5px 10px;
    }
  }
}


.roadtrip-premium-image{
  padding-top: 5rem !important;
}
.slider-search {
  padding-top: 55px;
}

.roadtrip-premium {
  max-width: 100% !important;

  &__blur{
    position: absolute;
    height: 50%;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, black -20%, transparent);
  }

  &__sale {
    position: relative;
    width: 20vh;
    font-weight: bold;

  }

  &__image {
    max-height: 60vh;
    object-fit: cover;
    object-position: center;
  

  }


}

.btn-open-desktop {
  position: absolute;
  top: 40px !important;
  right: 30px !important;
  z-index: 10 !important;
  border: 3px black solid !important;
}

.map-roadtrip-premium {
  height: 50vh;
  width: 100%;
  position: relative;
}


.roadtrip-premium-header {

  &__premium-data {
    bottom: 10%;
    position: absolute;
    margin-left: 5%;
    z-index: 10;
  }
  &__top {
    position: absolute;
    color: white;
    font-weight: 700 !important;
    top: 16%;
    left: 49%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 1.2rem !important;
  }

  &__top2 {
    position: absolute;
    color: white;
    font-weight: 700 !important;
    top: 30%;
    left: 49%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 1.2rem !important;
  }

  &__oldprice {
    position: absolute;
    color: white;
    font-weight: 700 !important;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.3rem;
  }

  &__barrier {
    position: absolute;
    background-color: white;
    width: 70%;
  }

  &__newprice {
    position: absolute;
    color: white;
    font-weight: 700 !important;
    top: 62%;
    left: 52%;
    transform: translateX(-50%);
    font-size: 2.4rem !important;
  }

  &__buy-container {
    position: absolute;
    right: 10vw;
    bottom: 4rem;
    z-index: 99;
  }

  &__buy-btn {

    background-color: #D1231A !important;
    border: #D1231A !important;
  }

  &__buy-btn:hover {
    transition-duration: 0.2s;
    background-color: darkred !important;
    border: darkred !important;
  }
}

@media screen and (max-width: 990px) {
  .roadtrip-premium-image{
    padding-top: 3rem !important;
  }
  .roadtrip-premium {
    &__image {
      height: 60vh;
      object-fit: cover;
      object-position: center;
      width: 100%;

    }

    &__blur{
      position: absolute;
      height: 50%;
      bottom: 0;
      width: 100%;
      z-index: 1;
      background: linear-gradient(0deg, black 5%, transparent);
    }

    &__sale {
      width: 20vh;
      font-weight: bold;
    }

  }

  .map-roadtrip-premium {
    height: 30vh;
    width: 100%;
    position: relative;
  }

  .btn-open-map {
    position: absolute;
    bottom: 7px !important;
    right: 2px !important;
    z-index: 10 !important;
    border: 3px black solid !important;

  }

  .roadtrip-premium-header {
    &__premium-data {
      bottom: 10%;
      position: absolute;
      margin-left: 0%;
      z-index: 10;
      width: 100%;
      text-align: center;
    }

    &__top {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      top: 16%;
      left: 49%;
      transform: translateX(-50%);
      text-transform: uppercase;
      font-size: 0.8rem !important;
    }

    &__top2 {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      top: 30%;
      left: 49%;
      transform: translateX(-50%);
      text-transform: uppercase;
      font-size: 0.8rem !important;
    }

    &__oldprice {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      top: 45%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1rem !important;
    }

    &__barrier {
      position: absolute;
      background-color: white;
      font-size: 1rem !important;
      bottom: 29%;
      left: 17%;
    }

    &__newprice {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      bottom: 17%;
      left: 52%;
      transform: translateX(-50%);
      font-size: 2rem !important;
    }

    &__buy-container {
      position: absolute;
      right: 7%;
      bottom: 11%;
      z-index: 99;
    }

    &__buy-btn {

      background-color: #D1231A !important;
      border: #D1231A !important;
    }

    &__buy-btn:hover {
      transition-duration: 0.2s;
      background-color: darkred !important;
      border: darkred !important;
    }
  }
}

@media screen and (max-width: 450px) {
  .roadtrip-premium-image{
    padding-top: 3rem !important;
  }
  .roadtrip-premium {

    &__image {
      height: 60vh;
      object-fit: cover;
      object-position: center;
      filter: brightness(70%);
      width: 100%;

    }

    &__sale {
      width: 20vh;
      font-weight: bold;
    }

  }

  .map-roadtrip-premium {
    height: 30vh;
    width: 100%;
    position: relative;
  }

  .btn-open-map {
    position: absolute;
    bottom: 7px !important;
    right: 2px !important;
    z-index: 10 !important;
    border: 3px black solid !important;

  }

  .roadtrip-premium-header {
    
    &__top {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      top: 16%;
      left: 49%;
      transform: translateX(-50%);
      text-transform: uppercase;
      font-size: 0.8rem !important;
    }

    &__premium-data {
      bottom: 10%;
      position: absolute;
      z-index: 10;
      width: 100%;
      text-align: center;
  }

    
    &__top2 {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      top: 30%;
      left: 49%;
      transform: translateX(-50%);
      text-transform: uppercase;
      font-size: 0.8rem !important;
    }

    &__oldprice {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      top: 45%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1rem !important;
    }

    &__barrier {
      position: absolute;
      background-color: white;
      font-size: 1rem !important;
      bottom: 29%;
      left: 17%;
    }

    &__newprice {
      position: absolute;
      color: white;
      font-weight: 700 !important;
      bottom: 17%;
      left: 52%;
      transform: translateX(-50%);
      font-size: 2rem !important;
    }

    &__buy-container {
      position: absolute;
      right: 23%;
      bottom: -8%;
      z-index: 99;
    }

    &__buy-btn {

      background-color: #D1231A !important;
      border: #D1231A !important;
    }

    &__buy-btn:hover {
      transition-duration: 0.2s;
      background-color: darkred !important;
      border: darkred !important;
    }
  }
}