.feedback-element {
  display: grid;
  grid-template-columns: min-content;
  grid-template-areas:
            "image user"
            "void content";
  gap: 1rem;
  padding: 2rem 0;
  position: relative;

  .feedback-avatar {
    grid-area: image;
  }

  .feedback-user {
    grid-area: user;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .feedback-content {
    grid-area: content;

    &::after {
      content: "";
      grid-area: content;
      position: absolute;
      bottom: -15px;
      left: 0;
      height: 2px;
      width: 50px;
      background-color: #cecece;
    }
  }
}

.feedback-section {
  .span-click{
    cursor: pointer;
  }
}


