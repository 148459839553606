.create-roadtrip-section{
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;

  .modify-btn{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3%;
    right: 0px;
    z-index: 9;
    .modify-st-btn{
      width: 30px;
      height: 30px;
      background-color: #004f91;
      outline: none;
      border: none;
      color: #fff;
      border-radius: 50%;
    }
  }
  .delete-btn{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    left: 10px;
    z-index: 9;
    .delete-st-btn{
      width: 30px;
      height: 30px;
      background-color: #dc3545;
      outline: none;
      border: none;
      color: #fff;
      border-radius: 50%;
    }
  }
  .dnd-container{
    width: 100%;
    li{
      text-decoration: none;
      list-style: none;
      .title{
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.touristic-site-page--header-image-RT, .touristic-site-page--header-image-RT::before{
  width: 80%; 
  border-radius: 10px;
  }
  


@media screen and (max-width: 990px){
  .create-roadtrip-section{
  .modify-btn{

 top: 5%;
    right: 5%;

  }


}
}