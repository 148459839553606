@mixin FeedbackPill($size) {
  border-radius: 50%;
  width: $size;
  height: $size;
}

.feedback-pill-md {
  @include FeedbackPill(20px);
  margin: 0 1rem 0 0;
}

.feedback-pill-sm {
  @include FeedbackPill(10px);
  margin: -0.15rem .5rem 0 0;
}
