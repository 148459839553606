.dropZone {
    background-size: cover;
    background-position: center;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: var(--backgroundColor);
    }

    &__btn {
        width: 50px;
        height: 50px;
        outline: none;
        border-radius: 50%;
        border: none;
        z-index: 2;
    }
}