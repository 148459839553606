.trust-slider {
  &__conteneur {
  height: 90px !important;
    float: none;
    border-radius: 20px;
  }
  &__image {
    object-fit: scale-down;
    width: 80%;
    height: 100%;
  }

  &__slider {
      width: 100%;
    height: 10em;
  }
}