.slick-list {
    overflow: visible !important;
}

.slick-track {
    display: flex !important;
    height: auto;
    align-items: center;
    justify-content: center;


    .slick-slide {
        & > div {
            height: 100%;
        }
    }

}
.slick-dots {
    bottom: -40px !important;
}
