.image {
    height: 2px;
    margin-left: 0;
    object-fit: cover;
    width: 13px;
  }

  .card{
      height: 190px !important;
      border-radius: 0.5em!important;

  }

  #root{
      background-color: #f8f9fa ;
  }