.dropContainer {
  &__title {
    font-family: Montserrat,sans-serif;
    font-weight: 500;
    font-size: 19px;

    @media screen and (max-width: 750px){
      font-weight: 550;
      font-size: 15px;
    }
  }

  &__svg {
    color: red !important;
  }

  &__text-option {
    color: grey;
  }
}