@import "../header.scss";

.title-header-bar{
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 750px){
        height: 500px;
    }

}

.search {
    @media screen and (max-width: 750px){
        padding-top: 200px;
    }
}

.welcome-header {
    color: var(--white);
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 55px;
    margin-right: 1.0px;
    padding-top: 170px;
    margin-bottom: 170px;
    min-height: 148px;

    text-align: center;
    white-space: nowrap;

    &__span{
        font-weight: 300;
    }

    @media screen and (max-width: 750px){
        font-size: 25px;
        font-weight: 800;
        padding-top: 20px;
    }

    @media screen and (max-width: 350px){
        font-size: 20px;
    }
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,100");
