.slick-dots li {
  button {
    &:before {
      content: '' !important;
      width: 20px !important;
      height: 2px !important;
      background-color: #000;
    }
  }
}

.slider-button {
  cursor: pointer;
  &__svg {
    background-color: white !important;
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    height: 40px;
    width: 40px;
    padding: 9.8px 13.0px;
  }
}
