.divider{
  display: flex;
  align-items: center;

  &__border{
    border-bottom: 1px solid black;
    width: 100%;
  }

  &__content {
    padding: 0 10px 0 10px;
  }
}
