.ambassador-join {
  &__image-top {
    width: 100%;
    height: 100%;
    @media (max-width:700px) and (min-width:800px){
      width: 70%;
      height: 70%;
    }
  }
  &__image-right {
    float: right;
    width: 40%;
    height: 40%;
  }

  &__text-div {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 750px) {
      margin-top: 5%;
    }
  }

  &__text {
    margin-left: 12px;
    font-size: 20px;
    @media screen and (max-width: 750px) {
      font-size: 17px;
    }
  }

  &__dot-div {
    width: 15px;
    height: 15px;
  }

  &__dot {
    height: 15px;
    width: 15px;
    background-color: #003e7e;
    border-radius: 50%;
    display: inline-block;
  }
}