.touristic-card-modal {
  &__container-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img-modal {
    border-radius: 1em;
    object-fit: cover;
    height: 300px;
  }

  &__content {
    height: auto;
    width: 85%;
  }

  &__title {
    color: #004f91;

    &__content {
      border-radius: 1em !important;
      height: auto;
      width: 400px;
      min-width: 230px;
    }

    &__circle-rank {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .form-control {
    height: 38px !important;
    text-align: center !important;
    vertical-align: middle !important;
    line-height: 24.5px !important;
  }

  .ui.button {
    height: 38px !important;
    text-align: center !important;
    vertical-align: middle !important;
    line-height: 19px !important;
  }
}

.audio_container {
  &__container-audio {
    $rhap_theme-color: #004f91 !default;
    $rhap_background-color: #004f91 !default;
    $rhap_bar-color: #004f91 !default;
  }
}

.ui-modal {
  box-shadow: 0px 5px 15px 11px #000000;
}

.ts-card-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
}

.custom-touristic-container {

  height: 100% !important;
  float: none;
  border-radius: 20px;
  display: flex !important;
  justify-content: center !important;
  width: 900px;
}

.custom-touristic-card {
  border-radius: 1rem;
  height: 20em;
  object-fit: cover;
  width: 80%;
}

.custom-touristic-btn-slick {
  display: flex;
  margin-top: 10%;
  justify-content: space-between !important;
}

.rhap_main-controls-button {
  $rhap_theme-color: #004f91 !default;
  $rhap_background-color: #004f91 !default;
  $rhap_bar-color: #004f91 !default;
  color: #004f91 !important;
  $rhap_time-color: #004f91 !default;
}

.rhap_container {
  box-shadow: 0 0 0 0 !important;
}

.rhap_container svg {
  $rhap_theme-color: #004f91 !default;
  $rhap_background-color: #004f91 !default;
  $rhap_bar-color: #004f91 !default;
  color: #004f91 !important;
  box-shadow: 0 !important;
}

.rhap_progress-indicator {
  background-color: #004f91 !important;
  color: #004f91 !important;
}

.rhap_download-progress {
  color: #004f91 !important;
}

.rhap_progress-filled {
  background-color: #004f91 !important;
}

.rhap_time {
  $rhap_theme-color: #004f91 !default;
  $rhap_background-color: #004f91 !default;
  $rhap_bar-color: #004f91 !default;
  color: #004f91 !important;
}

.rhap_volume-bar,
.rhap_volume-indicator {
  $rhap_theme-color: #004f91 !default;
  $rhap_background-color: #004f91 !default;
  $rhap_bar-color: #004f91 !default;
  color: #004f91 !important;
  $rhap_time-color: #004f91 !default;
}

.rhap_controls-section {
  $rhap_theme-color: #004f91 !default;
  $rhap_background-color: #004f91 !default;
  $rhap_bar-color: #004f91 !default;
  color: #004f91 !important;
  $rhap_time-color: #004f91 !default;
}

.btn-close {
  display: flex;
  justify-content: space-around;
  background-color: #004f91 !important;
  color: white !important;
}

.btn-quizz {
  display: flex;
  justify-content: space-around;
  background-color: #000080 !important;
  color: white !important;
  border-color: #000080 !important;
  margin-left: 1rem;
}

.div-btn-header {
  height: 5%;
  display: flex;
  flex-direction: row-reverse;
}

.btn-header {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 !important;

  &__close {
    background-color: transparent !important;
    color: transparent !important;
    box-shadow: transparent !important;
    padding: 0 !important;
    border-color: transparent !important;
  }
}

.div-btn-kahoot {
  display: flex;
  justify-content: center;
  color: #004f91;
  margin-top: 5%;
  align-items: center;
}

.div-btn-close {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  color: #004f91;
  margin-top: 5%;
}

.btn-rank {
  cursor: default;
  margin-right: 10px;
  border-radius: 40px;
  border: black solid 1px;
  height: 25px;
  background-color: white;
}

.react-player {
  &__custom {
    margin-bottom: 5%;
  }
}

.ts-card-rank {
  font-weight: bold;
  left: 48.5%;
  position: relative;
}

@media screen and (max-width: 990px) {
  .custom-touristic-container {
    height: 100% !important;
    float: none;
    border-radius: 20px;
    display: flex !important;
    justify-content: center !important;
  }

  .custom-touristic-card {
    border-radius: 10%;
    height: 20em;
    object-fit: cover;
    width: 100%;
    padding: 5%;
  }

  .ts-card-rank-container {
    position: relative;
  }

  .ts-card-rank {
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 8px;
  }

  .btn-header {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    &__close {
      background-color: transparent !important;
      color: transparent !important;
      box-shadow: transparent !important;
      border-color: transparent !important;
      padding: 0 !important;
    }
  }

  .ts-card-rank-container {
    position: relative;

  }

  .touristic-card-modal {
    &__container-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img-modal {
      border-radius: 1em;
      object-fit: cover;
      object-position: center;
      height: 200px;




      &__content {
        height: auto;
        width: 100%;
      }

      &__title {
        color: #004f91;

        &__content {
          border-radius: 1em !important;
          height: auto;
          width: 100%;
          min-width: 230px;
        }

        &__circle-rank {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }
      }

      .form-control {
        height: 38px !important;
        text-align: center !important;
        vertical-align: middle !important;
        line-height: 24.5px !important;
      }

      .ui.button {
        height: 38px !important;
        text-align: center !important;
        vertical-align: middle !important;
        line-height: 19px !important;
      }
    }
  }
}