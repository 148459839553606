.error404 {
  &__main-container {
    background:no-repeat center url("https://general-henri-1-paris.s3.fr-par.scw.cloud/Image-Page404.png");
    background-size: cover;
    height: 100vh;

    @media screen and (max-width: 750px) {
      background-position: 70% 70% ;
    }
  }

  &__text-container {
    position: absolute;
    top: 15%;
    left: 25%;
    @media screen and (max-width:750px){
      top: 15%;
      left: 5%;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
  &__text {
    font-size: x-large;
    font-weight: bold;
    color: #004f91;
    margin: 0;
  }

  &__number {
    position: relative;
    font-weight: 400;
    font-size: 30vh;
    color: #004f91;
    margin: 0;
    @media screen and (max-width:750px){
      font-size: 15vh;
    }
  }

  &__button {
    margin-left: 8%;
    width: 20%;
    @media screen and (max-width: 750px) {
      width: 100%;
      margin-left:0;
      margin-top: 15%
    }
    @media screen and (max-width:450px) {
      margin-left:0;
      margin-top: 10%
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 750px) {
      flex-direction: column;
    }
  }
}