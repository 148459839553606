.partner {
  &__image-style {
    border-radius: 20px;
    height: 20em;
    object-fit: cover;
    width: 90%;
  }

  &__content-text{
    height: 200px !important;
    margin-top: 15px;
    width: 90%;
  }

  &__slider {
    &__title {
      font-size: 22px;
      font-weight: bold;
    }
    &__description {
      font-size: 19px;
      font-weight: 390;
    }
  }
}
