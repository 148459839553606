@media screen and (max-width: 600px) {

    .experience-category {
        display: flex;
        flex-direction: column;
    }

    .experience-category-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .experience-category-icons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .dropdown_member {
        color: black;
    }

    .dropdown_member::content {
        color: black;
    }

    .group_member_day {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }

    .input-nbday {
        margin-bottom: 0 !important;
        margin-right: 1rem !important;
    }


    .inputWho {
        margin-bottom: 1rem !important;
    }

    .ui.menu {
        margin-top: 1rem !important;
        border: 3px solid #ced4da !important;
        border-radius: 0.5rem;


        .default.text {
            color: #495057 !important;
        }
    }

    .touristic-site-page--header-image {

        top: -15px !important;
    }

    .img-header-experience {
        width: 106% !important;
    }

    .experience-header-page {
        width: 200%;
        top: -15px;
        left: -15px;
        border-radius: 0 !important;

        img {
            border-radius: 0 !important;
        }
    }

    .suggest-modal {
        overflow-y: auto;
    }

    .form-group {
        margin-bottom: 0 !important;
        margin-top: 1rem !important;
    }

    .add_place {
        width: 100%;
        margin-top: 1rem;
        border: 1px dashed #024F91 !important;
        background-color: #D4E4FC !important;
        z-index: 3;
    }

    .div-add-place {
        z-index: -1;
        opacity: 0.7;
    }

    .experience-form-control {
        overflow-y: auto;
        margin: 0 !important;
    }

    .btn_delete_place {

        cursor: pointer;
        position: relative;
        padding: 2px 9px;
        font-size: 19px;
        text-transform: uppercase;
        color: black;
        background: rgba(220, 222, 220, 0.8);
        border: 3px solid rgba(255, 255, 255, 0.2);
        border-radius: 0.5rem;
        margin-left: 0.5rem;

        :hover {
            filter: invert(0.5)
        }
    }

    .place_input_delete {
        display: flex;
        flex-direction: column;
    }

}

@media screen and (min-width: 900px) {

    .experience-category {
        display: flex;
        flex-direction: column;
    }

    .experience-category-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .experience-category-icons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .dropdown_member {
        color: black;
    }

    .dropdown_member::content {
        color: black;
    }

    .group_member_day {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }

    .input-nbday {
        margin-bottom: 0 !important;
        margin-right: 1rem !important;
    }


    .inputWho {
        margin-bottom: 1rem !important;
    }

    .ui.menu {
        margin-top: 1rem !important;
        border: 3px solid #ced4da !important;
        border-radius: 0.5rem;


        .default.text {
            color: #495057 !important;
        }
    }

    .touristic-site-page--header-image {

        top: -15px !important;
    }

    .img-header-experience {
        width: 106% !important;
    }

    .experience-header-page {
        width: 200%;
        top: -15px;
        left: -15px;
        border-radius: 0 !important;
        margin: 0 !important;
        margin-top: 0 !important;

        img {
            border-radius: 0 !important;
            margin-top: 0 !important;
        }
    }

    .form-group {
        margin-bottom: 0 !important;
        margin-top: 1rem !important;
    }

    .add_place {
        width: 100%;
        margin-top: 1rem;
        border: 1px dashed #024F91 !important;
        background-color: #D4E4FC !important;
        z-index: 3;
    }

    .div-add-place {
        z-index: -1;
        opacity: 0.7;
    }

    .experience-form-control {
        overflow-y: auto;
        margin: 0 !important;
    }

    .btn_delete_place {

        cursor: pointer;
        position: relative;
        padding: 2px 9px;
        font-size: 19px;
        text-transform: uppercase;
        color: black;
        background: rgba(220, 222, 220, 0.8);
        border: 3px solid rgba(255, 255, 255, 0.2);
        border-radius: 0.5rem;
        margin-left: 0.5rem;

        :hover {
            filter: invert(0.5)
        }
    }

    .place_input_delete {
        display: flex;
        flex-direction: column;
    }

    .experience-image-page {
        width: 200%;
        top: -15px;
        left: -15px;
        border-radius: 0 !important;
        margin: 0 !important;
        margin-top: 0 !important;

        img {
            border-radius: 0 !important;
            margin-top: 0 !important;
        }
    }
}