.show-more-image {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 5.5em;
}

.text-color {
  font-size: 16px;
  font-weight: 500;
  color: #707070;
}

.show-more-background {
  background-color: #e7e7e7;
  border-radius: 20px;
  margin-top: 2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 2em;
}