@import "../../../assets/scss/functions";
@import "../../../assets/scss/variables";

.filter-button {
    p {
        padding-left: 10px;
        display: inline-block;

    }
}
