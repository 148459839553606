.ambassador-grade {
  &__image-grade {
    object-fit: contain;
    width: 165px;
    height: 165px;
    @media screen and (max-width: 750px) {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    color: #003E7E;
    font-weight: bold;
    font-size: 24px;
  }
  &__text-description {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 19px;
    text-align: center;
    @media screen and (max-width: 750px) {
      font-size: 20px;
    }
  }
}