.hr-class {
  width: 100%;

  @media screen and (max-width: 460px) {
    margin-top: 4rem !important;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 1rem !important;
  }
}

.title-switch {
  @media screen and (max-width: 460px) {
    height: 5.5rem !important;
  }
  @media screen and (max-width: 990px) {
    height: 4rem ;
  }

  @media screen and (max-width: 1200px) {
    height: 4.5rem ;
  }
}


.container_switch {
  margin: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 10px #1003;
}

.container_title {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 25px;
}

.carte {
  height: 60px;
  width: 100%;
  border-radius: 20px;
  @media screen and (max-width: 780px) {
    height: 65px;
  }
}

.carte_ot {
  height: 55px;
  border-radius: 20px;
}

.text-card {
  margin-top: 0.40rem !important;
}

.priceText {
  margin-left: 1%;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}