@import "../../assets/scss/functions";
@import "../../assets/scss/variables";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,100,200");

.overlap-group {
    border-radius: 40px;
    height: 85px;
    position: relative;
    width: 1055px;

    .adresse-gauche {
        align-items: flex-start;
        background-color: var(--white);
        
        border-radius: 40px;
        box-shadow: 4px 0 15px
        #00000041;
        display: flex;
        flex-direction: column;
        left: 0;
        min-height: 75px;
        padding: 14px 38px;
        position: absolute;
        top: 0;
        width: 48%;
        cursor: pointer;
        z-index: 99;

        @media screen and (max-width: 750px){

            width: 100%;
            min-height: 50px;
        }

        &:hover{
            background-color: whitesmoke;
            .autocompletePlace-input{
                background-color: whitesmoke;
                &:focus{
                    background-color: white;
                }
            }
        }

        &:focus-within{
            background-color: white;
        }
    }
    .adresse-gauche-logo .overlap-group{


    }

    .adresse {
        letter-spacing: 0;
        line-height: 16px;
        min-height: 21px;
        white-space: nowrap;
        @media screen and (max-width: 750px) {
            display: none;
        }
    }

    .input-address {
        outline: none;
        border: none;
        position: relative;
    }

    .o-allez-vous {
        letter-spacing: 0;
        line-height: 16px;
        margin-top: 3px;
        min-height: 21px;
        white-space: nowrap;
    }

    .dates {
        background-color: var(--white);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        left: 0;
        min-height: 75px;
        padding: 8px 253px;
        position: absolute;
        top: 0;
        width: 100%;
        cursor: pointer;

        &__title {
            align-self: center;
            letter-spacing: 0;
            line-height: 16px;
            margin-left: 36.0px;
            padding-top: 4px;
            min-height: 21px;
            min-width: 41px;
            white-space: nowrap;
        }

        &__input {
            position: absolute;
            line-height: 16px;
            padding-top: 4px;
            min-height: 21px;
            white-space: nowrap;
            letter-spacing: 0;
            border: none;
            width: 170px;
            left: 49%;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

        }

        &__clear-icon {
            position: absolute;
            right: 25%;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            @media screen and (max-width: 991px){
                right: 10%;
            }
        }
    }

    .react-datepicker{
        font-family: 'Montserrat', sans-serif;
        border-radius: 30px;
        top: 20px;
        right: -30px;
        @media screen and (max-width: 991px){
            right: 100px;
        }

        &__triangle{
            display: none;
        }

        &__navigation{
            top:20px
        }

        &__header{
            border: none;
            background-color: var(--white);
            border-radius: 30px;
            top: 10px;
            &:not(.react-datepicker__header--has-time-select){
                border-top-right-radius: 30px;
            }
        }
        &__month{
            padding: 10px;
            margin: 20px;
        }
        &__day{
            margin: 2px 0 0;
            width: 30px;
            height: 30px;
            border: 20px;
            padding-top: 4px;
            border-radius: 30px;
            &:hover{
                border-radius: 30px;
            }
            &--keyboard-selected{
                background-color: white;
                color: black;
            }

            &--range-start{
                background-color: #2a87d0;
            }
            &--range-end{
                color: white;
                background-color: #2a87d0;
            }
            &--keyboard-selected{

            }
            &--today{

            }
            &--outside-month{
                pointer-events: none;
                background-color: white;
                color: white;
            }
        }
    }




    .search-button {
        height: 53px;
        right: 1%;
        position: absolute;
        top: 11px;
        display: flex;
        align-items: center;
        border-radius:50rem!important;
        &__text{
            display: inline-block;
        }

        @media screen and (max-width: 991px){
            border-radius: 50%!important;
            width: 53px;
            &__text{
                display: none;
            }
        }
    }
    .rechercher {
        align-self: flex-end;
        letter-spacing: 0;
        line-height: 16px;
        margin-left: 12px;
        min-height: 21px;
        min-width: 102px;
        white-space: nowrap;
    }

    .locate-user {
        border-radius: 40px;
        height: 75px;
        width: 45%;
        margin-top: 90px;
        text-align: center;
        vertical-align: center;
        line-height: 75px;
        position: relative;
        cursor: pointer;
        &:hover{
            background-color: whitesmoke !important;
        }

        @media screen and (max-width: 750px) {
            margin-top: 62px !important;
            margin-right: auto;
            margin-left: auto;
            width: 250px;
            height: 50px;
            line-height: 50px;
        }
    }
}

