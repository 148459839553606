.bg-color-section-register{
  background-color: #004f91 !important;
  border-radius: 20px;
  cursor: pointer;
}

.button-section-register {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff !important;
  height: 3em !important;
  width: 95%;
  border-radius:20px;
  margin: 4%;



  @media screen and (max-width: 1024px){
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff !important;
    height: 3em !important;
    width: 95%;
    border-radius:20px;
    margin-left: 6%;

  }

  @media screen and (max-width: 768px){
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff !important;
    height: 3em !important;
    width: 95%;
    border-radius:20px;
    margin-left: 2%;
    margin-top: 10%;

  }
  @media screen and (max-width: 480px){
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff !important;
    height: 3em !important;
    width:95%;
    border-radius:20px;
    margin-left: 2%;


  }
  @media all and (max-width: 0px){
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff !important;
    height: 3em !important;
    width: 65%;
    border-radius:20px;
    margin-left: 6%;

  }
}

.title-section-register {
  color: #fff;
  font-size: 1.8em;
  text-align: center;
  @media screen and (max-width: 1024px){
    color: #fff;
    font-size: 1.5em;
    text-align: center;
  }

}


.logo-section-register {
  position: relative;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  max-width: 300px;
  display: flex;
  margin: auto;
  vertical-align: middle;
  top: 30%;

  @media screen and (max-width: 768px){
    margin-bottom: 6%;
    margin-top: 4%;
    top: 0;
  }
  @media screen and (max-width: 480px){
    width: 250px;
  }
}