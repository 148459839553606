.touristic-card {
  border-radius: 1em !important;
  height: auto;
  width: 310px;
  min-width: 230px;
  max-width: 310px;

  &--img {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    object-fit: cover;
    object-position: center;
    height: 200px;
  }

  &--title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    height: 2.4rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &--address {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    height: 1.2rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.touristic-horizontal-card{
  border-radius: 1em !important;
  width: 100%;
  height: 200px;
  min-width: 230px;

  &--img {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 200px;
  }
  &--description {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &--address {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &--title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  @media screen and (max-width: 990px){
    &--img {
      border-top-left-radius: 1em;
      border-bottom-left-radius: 0;
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
    }
    &--description {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--address {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &--title {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--category {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.activities-list{
  .slick-slide {
    margin: 0 10px;
  }
}
.activities-card{
  border-radius: 1em !important;
  width: 100%;
  height: 100px;

  &--img {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    object-fit: cover;
    object-position: center;
    width: 100px;
    height: 100px;
  }
  &--description {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &--address {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &--title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  @media screen and (max-width: 990px){
    &--img {
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
    }
    &--description {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--address {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &--title {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--category {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.blur-activity-container{
  .activities-card > div {
    & > div{
      filter: blur(2px);
    }
  }
  .blur-activity {
    width: 100%;
    height:100%;
    position:absolute;
    top: 0;
    left: 0;
    .lock-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .activity-auth__btn {
      display: none;
    }
  }
}