.leaflet-popup-content-wrapper {
  border-radius: 1em;
}

.leaflet-popup-close-button {
  display: none;
}


.blured-popup {
  display: flex;
  justify-content: space-between;
  border-radius: 1em;
  height: 10em;
  width: 30em;
  align-items: center;
  text-decoration: none;
  color: initial;
  transition: all 0.3s ease;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.blur-popup {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .lock-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .authentication_btn {
    display: none !important;
  }

  &:hover .lock-btn {
    display: none !important;
  }

  &:hover .authentication_btn {
    display: block !important;
  }
}

.blured-popup {
  img {
    transition: all 250ms ease-in-out;
  }

  div {
    transition: all 50ms ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover {
    .touristic-card>div {
      &>div {
        filter: blur(3px);
      }
    }
  }

  .roadtrip-elements {
    pointer-events: none;
  }

  .touristic-card>div {
    overflow: hidden;

    &>div {
      filter: blur(2px);
    }
  }
}
@media screen and (min-width: 800px) {
  .rt-popup-widget {
    &__touristiccard {
      width: 50% ;
    }

    &__container_category {
      padding: 0.2em;
    }
  }
}


.container-popup-map{
  @media screen and (min-width: 900px) {
    gap: 30px;
  }
}

.rt-popup-widget {
  &__touristiccard {
    bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: 1em 1em 0 0;
    cursor: default;
    height: 17vh;
    align-items: center;
    background-color: #fff;
    text-decoration: none;
    color: initial;
    transition: all 2s ease;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 2;
    -webkit-animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0px 0px;
    box-shadow: 6px 7px 13px -10px #000000;
    width: 70%;
    @media screen and (min-width: 900px){
      height: 18em;
      width: 700px;
    }
    @media screen and (max-width: 650px){
      height: 11em;
      width: 90%;
    }
  }
  &__iconCat{
    font-size: 0.9em;
    @media screen and (min-width: 800px) {
      width: 1.2em ;
    }
  }

  &__container_category {
    border: 1px solid rgb(0, 0, 0);
    height: 38px;
    width: 38px;
    margin-right: 0.4em;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    font-size: 1em;
    display: flex;
    align-content: center;
    align-items: center;
    font-weight: 800;
    margin: 0;
    @media screen and (min-width: 800px){
      font-size: 1.2em;
    }
  }

  &__img {
    object-fit: cover;
    object-position: center;
    max-height: calc(110px + 2vw);
    max-width: calc(225px + 2vw);
    box-shadow: 6px 7px 13px -10px #000000;
    border-radius: 0.7em 0.7em 0.7em 0.7em;

    @media screen and (min-width: 900px){
      height: 12em;
      width: 80%;
      max-height: max-content;
      max-width: max-content;
    }
  }
  &__svg {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    right: 25%;
    top: 35%;
    height: 80px;
    width: 80px;
    margin-right: 0.4em;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 900px){
      top: 20%;
    }
  }

  &__image_container{
    display: flex;
    justify-content: center;
    width: calc(225px + 2vw);
    height: calc(110px + 2vw);
    padding: 0.4em;
    position: relative;
    max-width: 35%;
    @media screen and (min-width: 900px){
      width: 190px;
      height: 230px;
    }
  }

  &__touristicBody {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .iconCat {
      padding-right: 0.5em;
    }


    @media screen and (max-width: 528px) {
      padding: 0.4em 0.6em;
      margin: 0;
    }
    @media screen and (min-width: 900px) {
      gap: 30px;
      flex-grow: 0;
      max-width: 50%;
    }
    @media screen and (min-width: 1200px) {
      justify-content: center;
    }

  }



  &__rates {
    .active {
      color: #004f91;
    }

    .disabled {
      color: #b4b4b4;
    }

    span {
      padding-left: 0.3em;
    }
  }

  &__iconClose {
    position: absolute;
    top: 0.2em;
    right: 0.4em;
    cursor: pointer;
    background-color: rgb(250, 152, 152);
    padding: 0.2em 0.3em;
    color: #fff;
    border-radius: 0.2em;

    &:hover {
      background-color: rgb(250, 95, 95);
    }
  }



  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

}