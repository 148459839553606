.leaflet-popup-content-wrapper {
  border-radius: 1em;
}
.leaflet-popup-close-button {
  display: none;
}
.popup {
  display: flex;
  justify-content: space-between;
  border-radius: 1em;
  height: 10em;
  width: 30em;
  align-items: center;
  text-decoration: none;
  color: initial;
  transition: all 0.3s ease;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.blur-popup {
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
  .lock-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .authentication_btn {
    display: none !important;
  }
  &:hover .lock-btn {
    display: none !important;
  }
  &:hover .authentication_btn {
    display: block !important;
  }
}
.popup {
  img {
    transition: all 250ms ease-in-out;
  }
  div {
    transition: all 50ms ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
  .roadtrip-elements {
    pointer-events: none;
  }
  .touristic-card > div {
    overflow: hidden;
    & > div{
      filter: blur(2px);
    }
  }
}
.popup-widget {
  &__touristiccard {
    display: flex;
    justify-content: space-between;
    border-radius: 1em;
    height: 10em;
    width: 30em;
    align-items: center;
    background-color: #fff;
    text-decoration: none;
    color: initial;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

  }
    @media screen and (max-width: 622px) {
    }

    @media screen and (max-width: 528px) {
      @media screen and (max-width: 750px) {
        width: 90%;
        bottom: 14vh;
      }
    }

    &__image {
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
      object-fit: cover;
      object-position: center;
      height: 10em;
      width: 140px;
      @media screen and (max-width: 622px) {
      }
      @media screen and (max-width: 528px) {
        border-radius: 1em 0 0 1em;
        width: 135px;
      }
    }

    &__touristicBody {
      flex-grow: 1;
      padding-left: 1em;
      display: flex;
      flex-direction: column;

      & .iconCat {
        padding-right: 0.5em;
      }

      @media screen and (max-width: 528px) {
        padding: 0.4em 1em;
        margin: 0;
      }
    }

    &__rates {
      .active {
        color: #004f91;
      }

      .disabled {
        color: #b4b4b4;
      }

      span {
        padding-left: 0.3em;
      }
    }

    &__iconClose {
      position: absolute;
      top: 0.2em;
      right: 0.4em;
      cursor: pointer;
      background-color: rgb(250, 152, 152);
      padding: 0.2em 0.3em;
      color: #fff;
      border-radius: 0.2em;

      &:hover {
        background-color: rgb(250, 95, 95);
      }
    }
  }
