@import './src/assets/scss/_functions.scss';
@import './src/assets/scss/_variables.scss';

.conversation-list-item {
    background-color: white;
    margin-top: -3px;

    li {
        padding: 1.5rem 1rem;

            &:hover {
                z-index: 1;
                border-color: $dark;
            }
            &.active {
                z-index: 1;
                border-color: $dark;
                color: $primary;
                background-color: white;
                font-weight: 600;
            }
    }


}