.card_container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 30px;
  margin-left: 15px;
  margin-top: 10px;
  box-shadow: 1px 1px 12px #888;
}

.card_text {
  margin: auto;
}