.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 991px) {
    padding-bottom: 70px !important;
  }
}
.donation-button{
  p {
    padding-left: 10px;
    display: inline-block;
  }
}
.donation-card{
  background-color: #004b8c;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
}

.logo {
  margin-left: 10px;
  height: 30px;
  width: 30px;
}
.logo-tiktok {
  margin-left: 10px;
  height: 30px;
  width: 30px;
  filter: invert(1);
}