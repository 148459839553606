.plane {
  transform: rotate(-90deg)
}

.navbar-logo {
  position: absolute;
  top: 2rem;
  left: 4rem;
  margin-right: 6rem;

}


.menu-nav-lateral {
  cursor: pointer;
}

.navbar-dropdown--item {
  justify-content: left !important;

  i.icon {
    margin-right: 11px !important;
    padding: 0 !important;
    color: #004f91 !important;
  }
}

.nav-item {
  margin: 15px !important;
}

.titre-comparateurs {
  color: black !important;
  font-size: 16px;
  font-weight: bold;
  justify-content: left !important;
}


.account {
  color: black !important;
}


.angle {
  position: absolute;
  right: -23px;
  top: 13px;
  color: white !important;

}

.hr {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}


.paddingSearch {
  padding-left: 40px !important;
  padding-right: 110px !important;

}

.navbar>.container {
  padding-right: 27px !important;
}

.navbar-collapse .nav-item-logout {
  margin: 0 7px !important;
}

// .navbar-collapse{
//     margin: 0 10px  !important;
// }
.bold {
  font-weight: bold 100;
  color: black;
}

nav ul.navbar-nav li a {
  padding: 8px 16px !important;

}

.navbar-mobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px !important;
}

.henri-pro {
  color: #fff !important;

}

nav ul.navbar-nav li {
  margin: 0 7px !important;
}

.burger {
  width: 32px;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  padding: 7px;
  border-radius: 50%;
  background-color: white;

  div {
    width: 100%;
    height: 2px;
    background-color: black;
  }
}

.burger2 {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 1px;
  padding: 7px;

  div {
    width: 90%;
    height: 2px;
    background-color: black;
  }
}

.menuNav {
  overflow-y: auto;
  list-style: none;
  position: fixed;
  top: 0;
  background: rgb(255, 255, 255);
  right: 0;
  bottom: 0;
  height: 100%;
  width: 30vw;


  z-index: 100;

  .menuNavTablet {
    width: 40px;
  }

  .menuNavPhone {
    width: 100vw !important;
  }

  a {
    display: block;
    padding: 10px 40px;
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

}

.dropdown-outils {
  margin-left: 0px !important;
}

.navbar-search {
  max-width: inherit;
  width: 25rem;

  padding-left: 0 !important;
  margin-left: 10rem;




  @media screen and (max-width: 720px) {
    width: 85% !important;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  @media screen and (max-width: 1315px) {
    width: 85% !important;
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

.nav-item:nth-child(3) {
  button {
    padding: 0px !important;
  }

  a {
    padding: 8px 16px !important;
  }

  i.angle.down.icon {
    margin: -4px 18px 0 0 !important;
  }

}

.offline {
  button {
    padding: 0px !important;
  }

  a {
    padding: inherit !important;
    margin: -3px -3px;
    margin-right: 30px !important;
  }

  i.angle.down.icon {
    margin: -5px 16px 0 0 !important;
  }

}