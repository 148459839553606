.autocompletePlace-input {
  outline: none;
  border: none;
  padding-left: 0;
  width: 90%;
}

.autocompletePlace-results {
}

.autocompletePlace-items {
  list-style: none;
  width: 100%;
  cursor: pointer;
  padding-top: 10px;

  &:hover{
    background-color: whitesmoke;
  }
}
.autocompletePlace-items-ts{
  list-style: none;
  width: 100%;
  cursor: pointer;
  padding-top: 10px;
  position: relative;

  .pin{
    background-image: url("../../../src/assets/img/logo/roundpushpin.svg");
    background-repeat: no-repeat;
    background-size: 22px;
    position: absolute;
    display: block;
    width: 20px;
    height:20px;
    left: -25px;
  }
}

.autocompletePlace-clear {
  position: absolute;
  align-self: flex-end;
  right: 10px;
}

.information_page .autocompletePlace-clear{
  top: 1.2rem;
}