.icon-activity {
  color: white;
}

.button-style {
  width: 100%;
  height: 70px;
  @media screen and (max-width: 480px) {
    padding-left: 0;
    width: 100%;
    height: 80px;
  }
}

.activity {
  flex: 0 0 auto;
  width: 50%;
  padding-left: 0.20rem !important;
  padding-right: 0.20rem !important;

  @media screen and (min-width: 768px) {
    flex: 0 0 auto;
    width: 33%;
  }

  @media screen and (min-width: 992px) {
    flex: 0 0 auto;
    width: 25%;
  }

  //col-lg-3
  @media screen and (min-width: 1200px) {
    flex: 0 0 auto;
    width: 20%;
  }
  p {
    font-weight: bold;
    text-align: center;
    font-size: 0.8em;
    @media (min-width: 360px) {
      font-size: 0.9em;
    }
  }

  button {
    cursor: default !important;
  }
}