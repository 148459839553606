.ambassador-text {
  &__text-style {
    font-size: 22px;
    @media screen and (max-width: 750px) {
      font-size: 19px;
    }
  }
  &__image {
    object-fit: cover;
    width: 130%;
    height: 130%;
    @media screen and (max-width: 750px) {
      width: 150%;
      height: 150%;
    }
  }
}