.image-style{
  border-radius: 20px;
  height: 10em;
  object-fit: contain;
  width: 100%;
  float: none;
  object-fit: scale-down;
}

h1{
 font-weight: bolder !important;
}

.show-text-style {
  color: #707070;
  font-size: 17px;
  font-weight: 500 !important;
}

.top-text-style {
  font-size: 17px;
  font-weight: 500 !important;
}