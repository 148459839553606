.image--ambassador {
  height: auto;
  object-fit: cover;
  width: 100%;
  @media screen and (max-width: 780px) {
    height: 75vh;
    width: 100%;
  }
}

.ambassador {
  &__text-position {
    position: absolute;
    top: 40vh;
    left: 10%;
    @media screen and (max-width: 1100px) {
      top: 15vh;
    }
    @media screen and (max-width: 1200px) {
      top: 20vh;
    }
  }
  &__button {
    width: 35%;
  }

  &__header-title {
    color: #004f91 !important;
    font-weight: bold;
    font-size: xx-large;
    line-height: 1.1;
  }
}


