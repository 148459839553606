.card_container {
  display: flex;
  flex-direction: row;
  width: fit-content;

  box-shadow: 1px 1px 12px #888;
}

.card_container_circle {

  display: block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  box-shadow: 1px 1px 12px #888;
}


.card_container_movable {
  display: block;
  width: 1rem;
  height: 1rem;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  text-align: center;
  background-color: white;
}

.card_container_circle img {
  margin-top: 7px;
}

.card_text {
  margin: auto;
}

.card_image {
  height: 70%;
  width: 70%;
}