.partner {
  &__header-background {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 180px;
    @media screen and (max-width: 750px){
      height: 82vh;
      padding-top: 30%;
    }
    @media screen and (max-width: 480px){
      height: 92vh;
      padding-top: 30%;
    }
  }
  &__header-background-demo {
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
      padding-top: 180px;
      @media screen and (max-width: 750px){
        background-position: 35% 50%;
        height: 82vh;
        padding-top: 30%;
      }
      @media screen and (max-width: 480px){
        height: 92vh;
        padding-top: 30%;
      }
    }


  &__button {
    width: 200px !important;
  }

  &__header-title {
    color: var(--white) !important;
    font-family: Montserrat, sans-serif;

    &__top {
      color: var(--white) !important;
      font-family: Montserrat, sans-serif;
      font-weight: bold;
      font-size: large;
    }
    &__middle {
      color: var(--white) !important;
      font-family: Montserrat, sans-serif;
      font-weight: bold;
      font-size: xx-large;
      line-height: 1;
    }
    &__bot {
      color: var(--white) !important;
      font-family: Montserrat, sans-serif;
      line-height: 1.2;
      font-size: large;
      font-weight: 550;
    }
  }
}

.welcome-header {
  color: var(--white);
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 55px;
  margin-right: 1.0px;
  padding-top: 170px;
  margin-bottom: 170px;
  min-height: 148px;

  text-align: center;
  white-space: nowrap;

  &__span{
    font-weight: 300;
  }

  @media screen and (max-width: 750px){
    font-size: 25px;
    font-weight: 800;
    padding-top: 20px;
  }

  @media screen and (max-width: 350px){
    font-size: 20px;
  }
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,100");
