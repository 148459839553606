.question-card {
  border-radius: 1em !important;
  border: 2px #004f91 solid;
  width: 200px;
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 5%;
  margin-right: 5%;
  cursor: pointer;
  position: relative;
  box-shadow: 0.2rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25) !important;

  &__img {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    object-fit: cover;
    object-position: center;
    height: 200px;
  }

  &__title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 700 !important
  }
}

.btn-question-more {
  margin-top: 10%;
}

.group-question-kahoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.div-btw {
  height: 103.5%;
  border-right: groove;
  border-right-color: rgba(0, 0, 0, 0.1);
}

.question-card:hover {

  opacity: 0.6;
}



.Kahoot-page {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-items: center;
  margin-top: 6.5%;

  &__underline {
    padding-right: 4%;
    padding-bottom: 15%;
    overflow-y: auto;
    height: 440px;
  }
}

.answers {
  border: none !important;
  background-color: transparent !important;
  color: white !important;
  font-size: 20px !important;
  resize: none;
  font-weight: 600;
  text-align: center;
}

.answers::placeholder {

  color: white !important;
  opacity: 0.6;
}



.answers-kahoot {
  display: grid;
  color: white;
  margin-top: 5%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 5%;
}

.kahoot-title {
  font-weight: 700;
}

.MuiSlider-root {
  display: flex;

}

.kahoot-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.answers1-kahoot {
  background-color: #730072;
  border-radius: 15px !important;
  height: 4.5rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.answers2-kahoot {
  background-color: blue;
  border-radius: 15px !important;
  height: 4.5rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.answers3-kahoot {
  margin-top: 10%;
  background-color: #BF8700;
  border-radius: 15px !important;
  height: 4.5rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.answers4-kahoot {
  margin-top: 10%;
  border-radius: 15px !important;
  height: 4.5rem !important;
  cursor: pointer;
  background-color: black;
  display: flex;
    justify-content: center;
}

.btn-right-kahoot {
  height: 3rem !important;
  display: flex !important;
  align-items: center !important;
  width: 42px;
  position: absolute;
  right: 5%;
  top: 5%;
}

.btn-left-kahoot {
  height: 3rem !important;
  display: flex !important;
  align-items: center !important;
  width: 42px;
  position: absolute;
  top: 5%;
  left: 5%;
  justify-content: center;
}


.checked-kahoot {
  display: flex;
  align-content: center;
  padding-top: 2%;
  padding-right: 25%;
}

.answers-kahoot-group {
  display: grid;
  justify-items: center;
}

.btn-kahoot-header {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  font-size: 1.5rem !important;

}

.btn_kahoot {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  margin-left: 5%;
  margin-right: 5%;
}

@media screen and (max-width: 990px) {


.checked-kahoot {

  padding-top: 0 !important;
  padding-right: 10% !important;
}


  .kahoot-question-btn {
    height: 8rem;
    display: flex;
    align-items: center;
  }

  .btn-right-kahoot {
    height: 3rem !important;
    display: flex !important;
    align-items: center !important;
    width: 42px;
    position: absolute;
    right: 5%;
    top: 5%;
  }

  .btn-left-kahoot {
    height: 3rem !important;
    display: flex !important;
    align-items: center !important;
    width: 42px;
    position: absolute;
    top: 5%;
    left: 5%;
    justify-content: center;
  }

  .answers-kahoot {
    display: grid;
    color: white;
    margin-top: 5%;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-bottom: 5%;
    font-size: 1.5rem !important
  }

  .answers {
    border: none !important;
    background-color: transparent !important;
    color: white !important;
    font-size: 20px !important;
    resize: none;
    font-weight: 800 !important;
    text-align: center;
  }

  .Kahoot-page {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    justify-content: center;
    &__underline {
      overflow-y: hidden;
      border-right: none;
      padding-right: 4%;
      padding-bottom: 15%;
      margin-top: 20%;
      height: auto;
    }
  }

  .btn_kahoot {
    margin-top: 20%;
  }

  .btn-kahoot-header {
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    font-size: 1rem !important;
  }

  .title-kahoot-header {
    padding: 0.575rem 0.75rem !important;
    font-weight: 600 !important;
  }

  .group-question-kahoot {
    margin-top: 20%;
    width: 100%;
  }

  .answers1-kahoot {
    background-color: #730072;
    border-radius: 15px !important;
    height: 3.5rem !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 0;

  }

  .answers2-kahoot {
    background-color: blue;
    border-radius: 15px !important;
    height: 3.5rem !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 0;

  }

  .answers3-kahoot {
    margin-top: 0;
    background-color: #BF8700;
    border-radius: 15px !important;
    height: 3.5rem !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .answers4-kahoot {
    margin-top: 0;
    border-radius: 15px !important;
    height: 3.5rem !important;
    cursor: pointer;
    background-color: black;
    display: flex;
    justify-content: center;
  }

}