.ambassador {
  &__image {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 750px) {
      width: 150%;
      height: 150%;
    }
  }

  &__card-image {
    width: 100%;
    height: 100%;
  }

  &__text-card {
    font-size: 20px;
    @media screen and (max-width: 750px) {
      font-size: 15px;
    }
  }

  &__second-image {
    position: relative;
    object-fit: cover;
    height: 100%;
    width: 180%;
    right: 30%;
    bottom: 50px;
  }
}
