.activity-page{
  .description {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .slick-track {
    margin-left: 1rem;
  }
}
.input-password-group{
  input{
    width: 52px !important;
    height: 52px !important;
    border-radius: 4px !important;
    color: #2b4660 !important;
    font-size: 25px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    padding: 0 !important;
    text-align: center !important;
    border: 1px solid #c4cdd6 !important;
    margin: 0 10px !important;
  }
  input:focus {
    border: 1px solid #2b4660 !important;
  }
}
.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex !important;
}