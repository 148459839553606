
.ellipsis-title-card {
    height:28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-content-card {
    height: 2.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.home-page-road-trip-card {
    border-radius: 1em;
    height: auto;
    width: 300px;

    transition: all 750ms ease-in-out !important;
    @media screen and (max-width: 768px) {
        display: block;
        width: 290px;
    }


    @media screen and (max-width: 767px) {
        width: 420px;
    }

    @media screen and (max-width: 426px) {
        width: 290px;
    }


    @media screen and (max-width: 340px) {
        width: 270px;
    }

    &--head {
        height: 135px;
    }

    &--img {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    &--ellipsis-content-card {
        height: 2.3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--roadtrip-wrapper{
        vertical-align: middle;
        height: 130px;

        flex-direction: column;
        list-style: thumbs;
        li {
            margin-bottom: auto;
        }
        li:first-child{

            margin-top: 0;
        }
        li:last-child{
            margin-bottom: 0;
            list-style-type: none
        }
    }
}

.roadtrip-img {
    max-height: 550px;
    object-fit: cover;
    object-position: center;
    min-height: 500px;
    @media screen and (max-width: 760px){
        min-height: 30vh;
    }
}

.roadtrip-header {
    padding-top: 20px !important;
}

.roadtrip-card {
    position: relative;
    border-radius: 1em;
    height: auto;
    width: 300px;
    transition: all 750ms ease-in-out !important;
    @media screen and (max-width: 768px){
        display:block;
        width: 324px;
    }

    @media screen and (max-width: 480px){
        width: 390px;
    }

    @media screen and (max-width: 460px){
        width: 370px;
    }

    @media screen and (max-width: 426px){
        width: 290px;
    }


    @media screen and (max-width: 340px){
        width: 270px;
    }

    &--macaron {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 50px;
        width: 50px;
    }

    &--macaron-currency {
        position: absolute;
        color: white;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        font-size: 1.9rem;
        font-weight: bold;
    }

    &--head{
        height: 150px;
    }
    &--img{
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    &--ellipsis-content-card {
        height: 2.9em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--roadtrip-wrapper{
        vertical-align: middle;
        height: 158px;
        flex-direction: column;
        list-style: thumbs;
        li {
            margin-bottom: auto;
        }
        li:first-child{

            margin-top: 0;
        }
        li:last-child{
            margin-bottom: 0;
            list-style-type: none
        }
    }

}

.list-icon {
    list-style: none;
    padding: 0 0 10px 20px;
    background-image: url("../../../../src/assets/img/logo/roundpushpin.svg");
    background-repeat: no-repeat;
    background-size: 22px;
}
