@import "../../../assets/scss/vendor/rfs";

.main {
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: start;
}

.title {
    @include rfs(3rem);
    font-weight: bold;
}