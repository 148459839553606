.patner_section_partner {
  &__image {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  &__text {
    font-family: Montserrat,sans-serif;
    font-weight: 600;
    font-size: 24px;
    @media screen and (max-width: 750px) {
      font-size: 20px;
    }
  }

  &__taille-image {
    @media screen and (min-width:990px){
      height: 200px !important;
    }
  }
}