.roadtrip-section{
  height: auto;
  transition: all 0.5s ease-in-out;
  &--slider {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }

  .slick-list{
    height: auto !important;
  }
  .slick-track{
    height: auto;
  }
  .slick-slide img {
    display: inline;
  }

  &--card{

  }
}

.categories-section {
  height: 480px;
  @media screen and (max-width: 960px) {
    height: 380px;
  }
}