@media screen and (max-width: 600px) {


    .container-experience-header {
        display: flex;
        justify-content: space-between;
    }

    .experience-first-data {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .day-number-experience {
        position: absolute;
        top: 10%;
        background-color: white;
        border-radius: 0.5rem;
        height: 3rem;
        width: 8rem;
        left: -16px;
        z-index: 1;
        text-align: center;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .price-experience {
        font-weight: 700;
    }

    .menu-experience {
        white-space: nowrap;
        margin-bottom: 1rem;
    }

    .menu-experience a {
        display: inline-block;
        color: grey !important;
        text-align: center;
        padding: 14px;
        font-size: 2rem;
        opacity: 0.6
    }

    .menu-experience a:hover {
        display: inline-block;
        color: #024F91 !important;
        text-align: center;
        padding: 14px;
        text-decoration: underline !important;
        font-size: 2rem;
        opacity: 1 !important
    }

    .text-description-experience {
        background-color: #D4E4FC;
        border: 1px dashed #024F91;
        width: 100%;
        height: 130px;
        border-radius: 0.5rem !important;
    }

    .experience_day {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }


    .experience_display_activity {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 80%;
    }

    .experience_day_render {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .add_day_experience {
        border: none;
        background-color: white;
    }

}