.result-bottom {
  font-weight: 700;
  font-size: 3.5rem;
  color: white;
  position: relative;
  /* bottom: -50%; */
  left: 30%;
  top: 45%;
}

.result-top {
  font-weight: 700;
  font-size: 3rem;
  color: white;
  position: relative;
  top: 15%;
  left: 20%;
}

.result-lign {
  transform: rotate(140deg);
  background-color: white;
  height: 2%;
  width: 90%;
  position: relative;
  top: 20%;
}

.kahoot-circle {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background: #004f91;
  position: relative;
  margin-bottom: 5%;
}

.kahoot-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kahoot-end {
  font-weight: 700;
  color: #004f91;
  font-size: 2rem;
}

@media screen and (max-width: 990px) {
  .kahoot-circle {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background: #004f91;
    position: relative;
    margin-bottom: 5%;
    left: 0;
  }

  .kahoot-result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .result-lign {
    top: 20%;
    height: 2%;
  }

  .result-bottom {
    font-weight: 700;
    font-size: 3.5rem;
    color: white;
    right: 0;
    bottom: -15%;
  }

  .result-top {
    font-weight: 700;
    font-size: 3rem;
    color: white;
    top: 20%;
    left: 20%
  }
}

@media only screen and (max-width: 990px) and (-webkit-device-pixel-ratio : 2) {
  .kahoot-circle {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background: #004f91;
    position: relative;
    margin-bottom: 5%;
    left: 0;
  }

  .kahoot-result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .result-lign {
    top: 40%;
    height: 2%;
    position: absolute;
    left: 4%;
  }

  .result-bottom {
    font-weight: 700;
    font-size: 3.5rem;
    color: white;
    right: 0;
    bottom: 25%;
    position: absolute;
  }

  .result-top {
    font-weight: 700;
    font-size: 3rem;
    color: white;
    top: 20%;
    left: 20%;
    position: absolute;
  }
}

@media only screen and (max-width: 990px) and (-webkit-device-pixel-ratio : 3) {
  .kahoot-circle {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background: #004f91;
    position: relative;
    margin-bottom: 5%;
    left: 0;
  }

  .kahoot-result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .result-lign {
    top: 40%;
    height: 2%;
    position: absolute;
    left: 4%;
  }

  .result-bottom {
    font-weight: 700;
    font-size: 3.5rem;
    color: white;
    right: 0;
    bottom: 25%;
    position: absolute;
  }

  .result-top {
    font-weight: 700;
    font-size: 3rem;
    color: white;
    top: 20%;
    left: 20%;
    position: absolute;
  }
}