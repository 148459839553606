@import './src/assets/scss/_functions.scss';
@import './src/assets/scss/_variables.scss';



.blur-page{
    position: relative;
}

.blur-page::after{
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0;
        right: 0;
        background-color: rgba(124, 124, 124, 0.5);


}
.roadtrip-page {

    .side-bar{
        position: fixed !important;
        top: inherit !important;
        @media screen and (max-width: 767px){
            position: fixed !important;
            top: 0 !important;
            padding-top: 20px;
            padding-right: 5px;
            left:0;
            width: 100% !important;
            height: 100%;
            .ui.right.overlay.sidebar {
                transform: inherit
            }
            .ui[class*="very wide"].right.sidebar {
                width: 100vw;
            }
            .ui.right.sidebar {
                left: inherit !important;   
            }      
        }
    }
    

    .ui.left.visible.sidebar, .ui.right.visible.sidebar{
        box-shadow: inherit !important;
    }

    .btn-sidebar{
        background-color: inherit;
        border: inherit; 
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
       
    } 
    hr{
        margin: 0 15px,
    }
    .btn-sidebar .close{
        margin: -5px;
       
    }
    .btn-sidebar:hover, .btn-sidebar:active{
        background-color: inherit;
        
    }  

   
    .secondary-title{
        color: rgba(0, 0, 0, 0.5) !important;
        font-size: 18px !important;
        
    
    }
    .menu-items{
        font-size: 16px !important;
    }
    .container {
        max-width: 100vw !important;
        padding: 0 0 0 0 !important;
    }
    .form-control {
        height: 38px !important;
        text-align: center !important;
        vertical-align: middle !important;
        line-height: 24.5px !important;
    }
    .ui.button {
        height: 38px !important;
        text-align: center !important;
        vertical-align: middle !important;
        line-height: 19px !important;
    }
    .categories{
        color: #004f91;
        font-weight: bold;
    }
    

}
.roadtrip-day {
    width: 100%;
}

.buttonStyle {
    border-radius: 50px !important;
    margin-bottom: 2rem;
}

.buttonAdd {
    border-radius: 112px !important;
    border-color: black;
    border-width: 1px;
    width: 2.5rem;
}

.d-grid {
    display: grid;
}
.roadtrip-elements {

    grid-template-columns: 1fr 20fr;
    grid-template-rows: auto;
    margin-bottom: 1.5rem;

    .roadtrip-element {
        grid-column: 2;
        max-width: 100%;

        &__obj{
            margin-left: auto !important;
            margin-right: auto !important;
            min-width: 230px;
            max-width: 310px;

            .delete-btn{
                position: absolute;
                margin-top: 10px;
                padding-left: 10px;
                z-index: 9;
            }
        }

    }

    .roadtrip-number {
        position: absolute;

        p {
            padding: 0.8rem;
            display: inline-block;
            background-color: $primary;
            text-align: center;
            width: 100%;
            color: white;
            border-radius: .2rem;
            cursor: move;
        }

        .roadtrip-delete-button {
            margin-top: 0.2rem;
            padding: 0.8rem;
            background-color: $danger;
            text-align: center;
            width: 100%;
            color: white;
            border-radius: .2rem;
            cursor: pointer;

            &:hover {
                background-color: darken($danger, 10%);
            }
        }
    }



}


.blur-roadtrip-container {
    img {
        transition: all 250ms ease-in-out;
    }
    div {
        transition: all 50ms ease-in-out;
    }
    &:hover .lock-btn {
        display: none;
    }
    &:hover .roadtrip-auth__btn {
        display: block;
    }
    &:hover img {
        transform: scale(1.1);
    }
    &:hover {
        .touristic-card > div {
            & > div{
                filter: blur(3px);
            }
        }
    }
    .roadtrip-elements {
        pointer-events: none;
    }
    .touristic-card > div {
        overflow: hidden;
        & > div{
            filter: blur(2px);
        }
    }

    .touristic-card-premium > div {
        overflow: hidden;
        & > div{
            filter: blur(2px);
        }
    }
}
.blur-roadtrip {
    width: 100%;
    height:100%;
    position:absolute;
    top: 0;
    left: 0;
    .lock-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .roadtrip-auth__btn {
        display: none;
    }
}

.map-mobile{
    position: absolute;
    width: 100vw;
    height: 100%;
    top:0;
    background-color: white;
    z-index: 1000;
}


@media screen and (max-width: 990px){


    .buttonAdd {
        border-radius: 112px !important;
        border-color: black;
        border-width: 1px;
        width: 10%;
    }

}
