.file-input-sort{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.btn-header {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0 !important;
    &__close {
        background-color: transparent !important;
        color: transparent !important;
        box-shadow: transparent !important;
        padding: 0 !important;
        border-color: transparent !important;
    }
}