.text-subtitle {
  font-weight: 600;
  font-size: 30px;
}

.current-balance {
  font-size: 24px;
}

.explain-paragraph {
  line-height: 1.2;
}