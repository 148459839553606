.categories-card {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 280px;
  height: 280px;
  border-radius: 1em;

  @media screen and (max-width: 990px) {
    width: 230px;
    height: 230px;
  }

  @media screen and (max-width: 750px) {
    width: 230px;
    height: 230px;
  }
}