.quote {
  &__image {
    &__left {
      position: relative;
      width: 2%;
      height: 2%;
      margin-right: 10px;
      @media screen and (max-width: 750px){
        margin-right: 0;
        bottom: 0;
        width: 4%;
        height: 4%;
      }
    }
    &__right {
      position: relative;
      width: 2%;
      height: 2%;
      top: 25px;
      @media screen and (max-width: 750px){
        top: 0;
        width: 4%;
        height: 4%;
      }
    }
  }

  &__text {
    &__content {
      font-size: 3.2vh;
      font-weight: 500;
      @media screen and (max-width: 750px){
        font-size: 2.5vh;
        font-weight: 600;
      }
    }
    &__author {
      font-size: 2.5vh;
      font-weight:390;
    }
  }

  &__main-container {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  &__wrap {
    position: relative;
    display: flex;
    max-width: fit-content;
    flex-direction: column;
  }

  &__top-container {
    text-align: start;
  }
  &__bot-container {
    text-align: end;
  }
}



