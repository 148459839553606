.bg-color-section-search{
  background-color: #004f91 !important;
  border-radius: 20px;
  cursor: pointer;
}


.overlap-group-suggest {
  border-radius: 40px;
  height: 85px;
  position: relative;
  width: 100%;

  .adresse-gauche {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 10px;
    border: 3px solid #ced4da;
    box-shadow: 2px 0 10px
    #00000041;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 14px 38px;
    position: relative;
    top: 0;
    width: 100%;
    cursor: pointer;
    z-index: 99;

    @media screen and (max-width: 750px){
      width: 100%;
      min-height: 50px;
    }

    &:hover{
      background-color: white;
      .autocompletePlace-input{
        background-color: white;
        &:focus{
          background-color: white;
        }
      }
    }

    &:focus-within{
      background-color: white;
    }
  }

  .adresse {
    letter-spacing: 0;
    white-space: nowrap;
    @media screen and (max-width: 750px) {
      display: none;
    }
  }

  .input-address {
    outline: none;
    border: none;
    position: relative;
  }

  .o-allez-vous {
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 3px;
    min-height: 21px;
    white-space: nowrap;
  }


  .adresse {
    display: none;
  }
}

