.section-video {
  &__video {
    width: 40%;
    margin-left: 30%;
    @media (max-width: 1024px) {
      margin-left: 10%;
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 80%;
    }

  }

  &__video-responsive {
    overflow:hidden;
    position:relative;
    display:flex !important;
    align-items: center !important;
    object-fit: contain;
    margin-top: 5%;

    @media screen and (max-width: 730px){
      margin-top: 10%;
    }
  }
    &__image {
      height: 100%;
      object-fit: contain;
      width: 100%;


    }
    &__text {
    font-size: 17px;
    font-weight: 450 !important;
  }
}