.get-answers1-kahoot {
  background-color: #730072;
  border-radius: 30px;
  height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.get-answers2-kahoot {
  background-color: blue;
  border-radius: 30px;
  height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.get-answers3-kahoot {
  margin-top: 10%;
  background-color: #bf8700;
  border-radius: 30px;
  height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.get-answers4-kahoot {
  margin-top: 10%;
  border-radius: 30px;
  height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.get-answers-kahoot {
  font-weight: 600;
  font-size: 1.3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem;
  color: white;
}

.get-kahoot-answers-container {
  display: flex;
  align-items: center;
}

.square-inversed {
  transform: rotate(45deg);
}

.kahoot-pouce {
  height: 4rem;
  width: 4rem;
}

.Kahoot-get-page {
  margin-left: 5%;
  margin-right: 5%;
}

.container-detail-pouce {
  display: flex;
  align-items: center;
}

.kahoot-img {
  height: 21rem;
  width: 23rem;
  border-radius: 5%;
}

.question-kahoot {
  font-size: 1.8rem;
  color: #004f91;
  font-weight: 800;
  margin-top: 20%;
  margin-left: 5%;
}

.kahoot-content {
  display: grid;
}

.detail-kahoot {
  color: black;
  font-size: 1.2rem;

  font-weight: 500;
}

@media screen and (max-width: 990px) {
  .get-answers-kahoot {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .bottom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -5px;
  }

  .question-kahoot {
    font-size: 1.5rem !important;
    margin-top: 0% !important;
  }

  .kahoot-content {
    display: flex;
    flex-direction: column;
  }

  .Kahoot-question {
    margin-bottom: 10%;
  }

  .quest-img-kahoot {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .get-answers1-kahoot {
    margin: 0 !important;
  }

  .get-answers2-kahoot {
    margin: 0 !important;
  }

  .get-answers3-kahoot {
    margin: 0 !important;
  }

  .get-answers4-kahoot {
    margin: 0 !important;
  }

  .kahoot-img {
    height: 16rem !important;
    width: 28.5rem !important;
    border-radius: 0;
  }
}
