.partner-info {
  &__image {
    &__logos {
      overflow: auto;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    &__font_romeu-logo {
      overflow: auto;
      width: 100%;
      object-fit: scale-down;
      @media (min-width:575px) {
        width: 50%;
      }

      @media (min-width: 992px) {
        width: 100%;
        height: 130px !important;
        object-fit: scale-down;
      }
    }

    &__tigne-logo {
      overflow: auto;
        width: 60%;
      object-fit: scale-down;
    }

    &__billeterie {
      overflow: auto;
      width: 100%;
      object-fit: contain;
    }

  }


  &__text {
    &__subtitles {
      font-family: Montserrat, sans-serif;
      font-weight: bold;
      font-size: 28px;
      @media screen and (max-width: 750px) {
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        font-size: 23px;
      }
    }
    &__content {
      font-family: Montserrat, sans-serif;
      font-size: 22px;
      @media screen and (max-width: 750px){
        font-family: Montserrat, sans-serif;
        font-size: 20px;
      }
    }

    &__right-align {
      text-align: end;
      @media screen and (max-width: 960px) {
        text-align: start;
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,100");