@import './src/assets/scss/_functions.scss';
@import './src/assets/scss/_variables.scss';

.feedback-pill-notation {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    margin: 0 .5rem 1rem 0;

    &.active {
        color: white;
        background-color: $primary;
    }
}
