.scroll-conversation {
    width: 100%;
    height: 50vh;

    @media screen and (min-width: 900px) {
        height: 65vh;
    }
}

.download-image {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.chat-image:hover > .download-image {
    opacity: 1;
}

.review-container {
    display: grid;
    grid-template-areas: "image content" "notes description";
    grid-template-columns: auto;
    gap: 1rem;
    border-bottom: 2px solid $secondary;

    .review-image {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        grid-area: image;
    }

    .review-notes {
        grid-area: notes;
    }

    .review-content {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        grid-area: content;
    }

    .review-description {
        grid-area: description;
    }
}

.marker-cluster-custom {
    top: -30px;
    padding: .5rem;
    background-color: $primary;
    color: $white;
    border-radius: 50%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    &::after {content: " ";
        position: absolute;
        top: 84%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -15px;
        border-width: 15px;
        border-style: solid;
        border-color: $primary transparent transparent transparent;
    }
}