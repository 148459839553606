.container_services {
    margin: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 10px #1003;
}

.image_style {
    width: 85px ;
    height: 85px;
    @media screen and (max-width: 750px) {
        width: 70px ;
        height: 70px;
    }
    object-fit: contain;
}
