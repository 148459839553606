.text-data {
  font-size: large;
  font-weight: bold;
}

.title-xlarge {
  font-size: x-large;
  font-weight: bold;
}

.subtitle {
  font-size: medium;
  font-weight: 600;
}