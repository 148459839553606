.ambassador-middle {
  &__card-image {
  object-fit: contain;
  width: 50%;
    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }

  &__text {
    font-size: 20px;
    text-align: center;
    @media screen and (max-width: 750px) {
      font-size: 15px;
    }
  }
}